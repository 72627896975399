import React from 'react';
import { changeLocale, IntlContextConsumer } from 'gatsby-plugin-intl';

const LanguageSelector = () => {
  const lang_to_img = {
    'de': '/de_DE.png',
    'en': '/en_GB.png',
  };

  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) =>
        languages.map(language => {
          if (language !== currentLocale) {
            return (
              <a key={language} onClick={() => changeLocale(language)} style={{ 'cursor': 'pointer' }}>
                <img src={lang_to_img[language]} alt={language}/>
              </a>
            );
          }
        })
      }
    </IntlContextConsumer>
  );
};

export default LanguageSelector;

