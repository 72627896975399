import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';
import { FormattedMessage } from 'gatsby-plugin-intl';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, minmax(140px, 200px));
    }

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 60%;
    border-radius: var(--border-radius);

    &:hover,
    &:focus {
      outline: 0;

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = ['Python', 'JavaScript', 'Java', 'SQL', 'React', 'Spring', 'Tensorflow', 'Vue', 'Kubernetes', 'Pytorch', 'Node.js', 'Docker', 'Kafka'];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading"><FormattedMessage id="About Me" /></h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              <FormattedMessage id="Hello, my name is Jonas and I have been studying computer science since 2018."/>
              {' '}
              <FormattedMessage id="I completed my bachelor's degree at the"/>
              {' '}<a href="https://www.uni-passau.de/"><FormattedMessage id="University of Passau"/></a>{' '}
              <FormattedMessage id="2021 completed."/>
              <br/>
              <FormattedMessage id="Since 2022, I am now studying for a Master's degree at the"/>
              {' '}
              <a href="https://www.tum.de/" target="_blank" rel="noreferrer">
                <FormattedMessage id="Technical University of Munich"/>
              </a>
              {' '}
              <FormattedMessage id="with a focus on Machine Learning and Data Analysis."/>
              <br/>
              <FormattedMessage
                id="From 2019 to 2023, I worked as a working student at the software development and consulting company"/>
              {' '}
              <a href="https://iamds.com/" target="_blank" rel="noreferrer">
                IAMDS
              </a>
              .
              <br/>
              <FormattedMessage
                id="During this time, I was involved in a variety of projects ranging from web development and machine learning to building the"/>
              {' '}<a href="https://iamds.com/iamds-data-platform/">IAMDS Dataplatform</a>.
              <br/>
              <FormattedMessage
                id="I then spent nine months as a working student at Siemens, where I took on exciting tasks in areas such as anomaly detection and data analysis of various industrial data. I am currently taking part in the"/>
              {' '}<a href="https://www.appliedai-institute.de/ai-career-kickstart-program" target="_blank" rel="noreferrer">AI Career Kickstart Program</a>{' '}
              <FormattedMessage id="from AppliedAI and am doing an internship at Bosch as part of this. There I am working on the application of large language models and the solution of time series problems in the field of machine learning." />
            </p>

            <p><FormattedMessage id="I am well acquainted with these technologies"/>:</p>
          </div>

          <ul className="skills-list">
          {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            {/*<StaticImage
              className="img"
              src="../../../static/me.png"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />*/}
            <img src='/me.png' alt='Headshot'/>
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
