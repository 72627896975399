import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';
import {FormattedMessage, useIntl} from "gatsby-plugin-intl";

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  height: 100vh;
  padding: 0;

  @media (max-height: 700px) and (min-width: 700px), (max-width: 360px) {
    height: auto;
    padding-top: var(--nav-height);
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 5px;
    color: var(--slate);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 550px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();
  const intl = useIntl();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1><FormattedMessage id="Hi, my name is" /></h1>;
  const two = <h2 className="big-heading">Jonas Zausinger</h2>;
  const three = <h3 className="big-heading"> </h3>;
  const four = (
    <>
      <p>
        <FormattedMessage id="I am studying M.Sc. Computer Science at the" />
        {' '}
        <a href="https://www.tum.de/" target="_blank" rel="noreferrer">
          <FormattedMessage id="Technical University of Munich" />
        </a>
        .
        <br/>
        <FormattedMessage id="Besides I am a participant in the" />
        {' '}
        <a href="https://www.appliedai-institute.de/ai-career-kickstart-program" target="_blank" rel="noreferrer">
          AI Career Kickstart Program
        </a>
        {' '}
        <FormattedMessage id="from AppliedAI and I am currently doing a five-month internship at Bosch as part of this programme." />
        <br/>
        <FormattedMessage id="My main interests are machine learning, data analysis as well as backend development." />
      </p>
    </>
  );
  const five = (
    <a
      className="email-link"
      href={`/${intl.locale}/JonasZausinger.pdf`}
      target="_blank" rel="noopener noreferrer"
    >
      <FormattedMessage id="Resume" />
    </a>
  );

  const items = [one, two, four, five];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
